<template>
    <div v-if="vuelidate[name].$invalid">
       <small
          class="p-error"
          v-for="error of vuelidate[name].$errors"
          :key="error.$uid"
          >{{ error.$message }}</small
       >
    </div>
 </template>
 
 <script lang="ts">
 import { defineComponent, PropType } from 'vue'
 import { Validation } from '@vuelidate/core'

 export default defineComponent({
    props: {
       name: {
          type: String,
          required: true
       },
       vuelidate: {
          type: Object as PropType<Validation>,
          required: true
       }
    }
 })
 </script>
 